@import "./_variables";
@import "./sass/_layout";
@import "./_overrides";

.pdt
{
    padding-top : 130px !important;
}
.mdb
{
    margin-bottom : 160px !important;
}
.imground
{
    border-radius: 50%;
    height: 50px;
}
.imground2
{
    border-radius: 50%;
}
.mdl
{
    margin-left : 13px !important;
}
.pd-10
{
    padding-top : 10px !important;
}
.text-danger
{
    color: #EF4444 !important;
    font-size: 13px !important;
}


@media screen and (max-width: 400px) {
    .p-fluid
    {
        width: 350px !important;
    }
}
@media screen and (max-width: 575px) {
    .p-fluid
    {
        width: 300px !important;
    }
}
@media screen and (max-width: 767px) {
    .p-fluid
    {
        width: 300px !important;
    }
}